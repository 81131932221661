import React from 'react';
import { PageContainer } from '@amzn/stencil-react-components/page';

import Header from '../components/header';

export default function NotFoundPage() {
  return (
    <>
      <Header />
      <PageContainer>
        <h1>Page not found</h1>
      </PageContainer>
    </>
  );
}
